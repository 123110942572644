import React from "react";
import "./ConversationsList.scss";

import { ConversationList, Conversation, Avatar } from "@chatscope/chat-ui-kit-react";

import swLogo from "assets/sw-logo.png";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ConversationsList = () => {
  const { t } = useTranslation();
  const { favoriteTeamChatId, favoriteTeamChat, globalChatId, lastConversations } = useStoreState(
    (store) => store.chat
  );
  const { profileImage } = useStoreState((store) => store.user);
  const navigate = useNavigate();

  return (
    <div className="chat_container">
      <ConversationList>
        {globalChatId && (
          <Conversation
            name={t("chat.globalChat")}
            info={
              lastConversations?.[globalChatId]?.text
                ? parse(lastConversations[globalChatId].text)
                : lastConversations?.[globalChatId]?.mediaURL
                  ? "image"
                  : t("chat.noMessagesYet")
            }
            lastSenderName={lastConversations[globalChatId]?.sender?.nickname || null}
            className="conversation_item"
            lastActivityTime={moment(lastConversations[globalChatId]?.created).format("HH:mm")}
            onClick={() => navigate(globalChatId)}
          >
            {/*<Avatar src={swLogo} name="Global" className="avatar" />*/}
          </Conversation>
        )}
        {favoriteTeamChatId && (
          <Conversation
            name={t(favoriteTeamChat?.name.replace("PUBLIC FAN CLUB", "").trim()) + " Chat"}
            lastSenderName={lastConversations[favoriteTeamChatId]?.sender?.nickname || null}
            info={
              lastConversations?.[favoriteTeamChatId]?.text
                ? parse(lastConversations[favoriteTeamChatId].text)
                : lastConversations?.[favoriteTeamChatId]?.mediaURL
                  ? "image"
                  : t("chat.noMessagesYet")
            }
            className="conversation_item"
            lastActivityTime={moment(lastConversations[favoriteTeamChatId]?.created).format(
              "HH:mm"
            )}
            onClick={() => navigate(favoriteTeamChatId)}
          >
            {/*<Avatar*/}
            {/*  src={profileImage}*/}
            {/*  name={favoriteTeamChat?.name.replace("PUBLIC FAN CLUB", "")}*/}
            {/*  className="avatar"*/}
            {/*/>*/}
          </Conversation>
        )}
      </ConversationList>
    </div>
  );
};
export default ConversationsList;
