import React, { useEffect, useState } from "react";

import styles from "./OddMultipleOptions.module.scss";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "easy-peasy";
import SelectComponent from "components/SelectComponent/SelectComponent";

const OddMultipleOptions = ({ odd, team_one, team_two, userBets }) => {
  const { t } = useTranslation();
  const { setBets } = useStoreActions((actions) => actions.matches);
  const { bets } = useStoreState((actions) => actions.matches);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  const teams = [team_one, team_two];
  function getTeamNameById(id) {
    const team = teams.find((team) => team.id === id);
    return team ? team.name : false;
  }
  useEffect(() => {
    if (odd?.oddOptions?.length > 0) {
      setSelectOptions(
        odd?.oddOptions
          ?.map((item) => {
            return {
              ...item,
              points: item.value,
              team: item?.teamId,
              value: item._id,
              label: `${item.name}${item.teamId && getTeamNameById(item.teamId) ? ` (${t(getTeamNameById(item.teamId))})` : ""}`,
            };
          })
          .sort((a, b) => (a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0))
      );
    }
  }, [odd]);

  useEffect(() => {
    if (selectOptions?.length) {
      setSelectedOption(selectOptions.find((item) => item.value === bets[odd?.gameId][odd?._id]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions, bets]);

  useEffect(() => {
    if (selectedOption) oddHandler(selectedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const oddHandler = (item) => {
    const payload = {
      gameId: odd?.gameId,
      oddId: odd?._id,
      oddOptionId: item?._id,
    };
    setBets(payload);
  };
  return (
    <div className={styles.odd_selector}>
      <h2>{t(odd?.name)}</h2>
      <div className={styles.odd_options}>
        <SelectComponent
          label={`Select ${t(odd?.name).toLowerCase()}`}
          options={selectOptions}
          value={selectedOption}
          setValue={(e) => {
            setSelectedOption(e);
          }}
          forOddOptions={true}
        />
        <p className={styles.value}>
          {selectedOption?.points.toFixed(2)} {t("matches.pts")}
        </p>
      </div>
    </div>
  );
};
export default OddMultipleOptions;
