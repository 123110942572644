import React, { useEffect, useRef, useState } from "react";
import styles from "./Prizes.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useStoreState } from "easy-peasy";

const Prizes = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const { lang, country } = useStoreState((store) => store.user);
  const DACH = ["AT", "CH"];

  console.log(country, "country")
  const DEPrizes = [
    {
      title: t("DEPrize1Title"),
      subtitle: t("DEPrize1Subtitle"),
      image_de: "/images/BestPlayer_TV_DEonly_de.png",
      image_en: "/images/BestPlayer_TV_DEonly_de.png",
    },
    {
      title: t("DEPrize2Title"),
      subtitle: t("DEPrize2Subtitle"),
      image_de: "/images/DailyWinners_Mobile_DEonly_de.png",
      image_en: "/images/DailyWinners_Mobile_DEonly_de.png",
    },
    {
      title: t("DACHPrize1Title"),
      subtitle: t("DACHPrize1Subtitle"),
      image_de: "/images/BestTeam_Sportworld_de.png",
      image_en: "/images/BestTeam_Sportworld_de.png",
    },
  ];

  const DACHPrizes = [
    {
      title: t("DACHPrize1Title"),
      subtitle: t("DACHPrize1Subtitle"),
      image_de: "/images/BestTeam_Sportworld_de.png",
      image_en: "/images/BestTeam_Sportworld_de.png",
    }
  ];

  const WorldPrizes = [
    {
      title: t("WorldPrize1Title"),
      subtitle: t("WorldPrize1Subtitle"),
      image_de: "/images/BestPlayer_Sportworld_en.png",
      image_en: "/images/BestPlayer_Sportworld_en.png",
    },
    {
      title: t("WorldPrize2Title"),
      subtitle: t("WorldPrize2Subtitle"),
      image_de: "/images/BestTeam_Sportworld_en.png",
      image_en: "/images/BestTeam_Sportworld_en.png",
    },
  ];

  useEffect(() => {
    if (lang === "de" || country === "DE") {
      setPrizes(DEPrizes);
    } else if (DACH.includes(country)) {
      setPrizes(DACHPrizes);
    } else {
      setPrizes(WorldPrizes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, country]);

  const [prizes, setPrizes] = useState(WorldPrizes);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={classNames(styles.prizes_container, "container")} ref={ref}>
      <div className={styles.filters}>
        <button
          className={classNames(styles.button, {
            [styles.active]: activeIndex === 0,
          })}
          onClick={() => {
            setActiveIndex(0);
          }}
        >
          {t("menu.prizes")}
        </button>
        <button
          className={classNames(styles.button, {
            [styles.active]: activeIndex === 2,
          })}
          onClick={() => {
            setActiveIndex(2);
          }}
        >
          {t("leaderboards.rules")}
        </button>
      </div>
      {activeIndex !== 2 ? (
        <div className={classNames(styles.prizes)}>
          {prizes.map((item, index) => {
            return (
              <div key={index} className={classNames(styles.prize_card)}>
                <div className={classNames(styles.prize_card__images)}>
                  <img src={lang === "de" ? item.image_de : item.image_en} alt={item.title} />
                </div>
                <div className={classNames(styles.prize_card__info)}>
                  <span className={classNames(styles.title)}>{item.title}</span>
                  <span className={classNames(styles.subtitle)}>{item.subtitle}</span>
                </div>
              </div>
            );
          })}
        </div>
      ) : lang === "de" ? (
        <div className={styles.rules}>
          <h2>SO SAMMELST DU PUNKTE UND STEIGERST DEINE CHANCE AUF ATTRAKTIVE PREISE:</h2>
          <br />
          <br />

          <h3>PUNKT 1: TIPPE JEDEN SPIELTAG</h3>
          <p>
            Besuche die Sportworld App, um deine Tipps abzugeben oder zu ändern. Tippe auf den Sieger und das genaue Endergebnis jeder Partie. Das Tippen ist bis zum Anstoß des jeweiligen Spiels möglich.
          </p>
          <br />
          <br />

          <h3>PUNKT 2: SAMMLE PUNKTE AUF BASIS VON ECHTEN WAHRSCHEINLICHKEITEN</h3>
          <p>
            Punkte werden basierend auf echten Wahrscheinlichkeiten vergeben. Tipps mit hoher Wahrscheinlichkeit bringen weniger Punkte, risikoreiche mehr. Dies sorgt für einen dynamischen und spannenden Wettbewerb.
          </p>
          <br />
          <br />

          <h3>PUNKT 3: TIPPE AUF DEN CHAMPIONS LEAGUE-SIEGER</h3>
          <p>
            Der Tipp auf den Champions League-Sieger ist von Anfang an möglich und kann bis zum ersten K.o.-Spiel angepasst werden. Anfänglich bringt dieser Tipp viele Punkte, die sich je nach Leistung des Teams reduzieren können.
          </p>
          <br />
          <br />

          <h3>PUNKT 4: ZUSÄTZLICHE FRAGEN AB DER K.O.-PHASE</h3>
          <p>
            Ab der K.o.-Phase kannst du zusätzliche Fragen beantworten, wie „Wer schießt ein Tor?“ oder „Wer gewinnt die erste Halbzeit?“, um extra Punkte zu sammeln und auch in den späteren Phasen noch erfolgreich zu sein.
          </p>
          <br />
          <br />

          <h3>PUNKT 5: GEWINNE ATTRAKTIVE PREISE</h3>
          <p>
            Der Gesamtsieger und die jeweiligen Tagessieger haben die Chance auf attraktive Preise. Alle Details hierzu findest du unter dem Tab „Preise“.
          </p>
          <br />
          <br />

          <p>
            Viel Spaß und Erfolg beim Mitmachen!
            <br /><br />
            Hinweis: Apple und Google sind nicht an diesem Tippspiel beteiligt und stellen keinen Sponsor dar.
          </p>
          <br />
        </div>
      ) : (
        <div className={styles.rules}>
          <h2>HOW TO COLLECT POINTS AND INCREASE YOUR CHANCE OF WINNING ATTRACTIVE PRIZES:</h2>
          <br />
          <br />

          <h3>POINT 1: MAKE YOUR PREDICTIONS FOR EACH MATCHDAY</h3>
          <p>
            Visit the Sportworld app to submit or modify your predictions. Predict the winner and the exact final score of each match. Predictions can be made up until the kickoff of each game.
          </p>
          <br />
          <br />

          <h3>POINT 2: COLLECT POINTS BASED ON REAL PROBABILITIES</h3>
          <p>
            Points are awarded based on real probabilities. Predictions with high likelihood bring fewer points, while riskier ones earn more. This creates a dynamic and exciting competition.
          </p>
          <br />
          <br />

          <h3>POINT 3: PREDICT THE CHAMPIONS LEAGUE WINNER</h3>
          <p>
            You can predict the Champions League winner from the start, and adjust your prediction until the first knockout game. Initially, this prediction will earn many points, which may decrease depending on the team’s performance.
          </p>
          <br />
          <br />

          <h3>POINT 4: ANSWER ADDITIONAL QUESTIONS FROM THE KNOCKOUT PHASE</h3>
          <p>
            Starting from the knockout phase, you can answer additional questions like "Who will score a goal?" or "Who will win the first half?" to gather extra points and stay competitive in the later stages.
          </p>
          <br />
          <br />

          <h3>POINT 5: WIN ATTRACTIVE PRIZES</h3>
          <p>
            The overall winner and daily winners have a chance to win attractive prizes. You can find all the details under the “Prizes” tab.
          </p>
          <br />
          <br />

          <p>
            Good luck and have fun participating!
            <br /><br />
            Note: Apple and Google are not involved in this prediction game and are not sponsors.
          </p>
          <br />
        </div>
      )}
    </div>
  );
};

export default Prizes;
