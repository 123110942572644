import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api from "../../api/axios";
import { GAME, MATCHES, urlParamReplacer } from "./apiRoutes";

const gameModel = {
  // DATA
  testMode: false,
  chalangeId: "",
  teams: [],

  //ACTIONS
  setTestMode: action((state, mode) => {
    state.testMode = mode;
  }),
  setChalangeID: action((state, id) => {
    state.chalangeId = id;
  }),
  setTeams: action((state, teams) => {
    state.teams = teams;
  }),

  //THUNKS
  getTestMode: thunk(async (actions, payload) => {
    actions.setTestMode(payload);
  }),
  getChalangeID: thunk(async (actions) => {
    try {
      const { data } = await api.get(MATCHES.CHALLENGES);
      if (data) {
        actions.setChalangeID(data._id);
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getData: thunk(async (actions) => {
    const competitionId = process.env.REACT_APP_COMPETITION_ID;
    try {
      const { data } = await api.get(urlParamReplacer(GAME.TEAMS, competitionId));
      const removedTeams = [
        "Group A",
        "Group B",
        "Group C",
        "Group D",
        "Group E",
        "Group F",
        "Group Stage",
        "Quarter Final",
        "Round of 16",
        "Runner Up",
        "Semi Final",
        "To Win Outright",
        "Andorra",
        "Armenia",
        "Azerbaijan",
        "Belarus",
        "Bosnia and Herzegovina",
        "Bulgaria",
        "Cyprus",
        "Estonia",
        "Faroe Islands",
        "Finland",
        "Gibraltar",
        "Greece",
        "Iceland",
        "Ireland",
        "Israel",
        "Kazakhstan",
        "Latvia",
        "Liechtenstein",
        "Luxembourg",
        "Malta",
        "Moldova",
        "Montenegro",
        "Northern Ireland",
        "Norway",
        "Republic of Kosovo",
        "Russia",
        "San Marino",
        "North Macedonia",
        "Sweden",
        "Wales",
        "Lithuania",
      ];
      const filteredData = data?.filter((item) => removedTeams?.includes(item?.name) === false);
      actions.setTeams(filteredData);
    } catch (error) {
      console.error(error);
    }
  }),
};
export default gameModel;
