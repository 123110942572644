import React from "react";

import styles from "./NoOdds.module.scss";
import { useTranslation } from "react-i18next";

const NoOdds = ({ name }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.no_odds}>
      <h2>{t(name)}</h2>
      <div className={styles.container}>
        <p>{t("No odds available")}</p>
      </div>
    </div>
  );
};

export default NoOdds;
