import React, { useEffect, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useTranslation } from "react-i18next";

import { LoaderWithLogo, TermsPopUp } from "components";
import checkedIcon from "assets/icons/checked-icon.svg";

import parse from "html-react-parser";
import styles from "./Register.module.scss";
import classNames from "classnames";
import Select from "components/Select/Select";
import TeamsPopup from "components/TeamsPopup/TeamsPopup";

const regexNickname = /^[a-zA-Z0-9_]{1,15}$/;

const Register = () => {
  const { access_token, error, isLoading, nickname, email } = useStoreState((store) => store.user);
  const { register } = useStoreActions((actions) => actions.user);
  const { teams } = useStoreState((store) => store.game);
  const { getData } = useStoreActions((actions) => actions.game);
  const teamOptions = [];
  const { t } = useTranslation();

  const [newNickname, setNewNickname] = useState("");
  const [newEmail, setNewEmail] = useState(email || "");
  const [nicknameError, setNicknameError] = useState();
  const [termsError, setTermsError] = useState();
  const [teamError, setTeamError] = useState();
  const [teamsPopupIsOpen, setTeamsPopupIsOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();

  const [yeppTermsPopUpIsOpen, setYeppTermsPopUpIsOpen] = useState(false);
  const [swTermsPopUpIsOpen, setSwTermsPopUpIsOpen] = useState(false);

  const [yeppTermsAreAccepted, setYeppTermsAreAccepted] = useState(false);
  const [swTermsAreAccepted, setSwTermsAreAccepted] = useState(false);

  useEffect(() => {
    if (access_token) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  useEffect(() => {
    teams?.forEach((team) => {
      teamOptions.push({ value: team.name, label: team.name });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  useEffect(() => {
    if (yeppTermsPopUpIsOpen || swTermsPopUpIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [yeppTermsPopUpIsOpen, swTermsPopUpIsOpen]);

  useEffect(() => {
    if (error) {
      setNicknameError(t("This nickname is already used. Please change it"));
    } else {
      setNicknameError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (nickname) {
      setNewNickname(nickname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nickname]);

  useEffect(() => {
    if (email) {
      setNewEmail(email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (swTermsAreAccepted) {
      setTermsError();
    }
  }, [swTermsAreAccepted]);

  const nicknameHandler = (e) => {
    setNewNickname(e.target.value);
    if (regexNickname.test(e.target.value) && e.target.value) {
      setNicknameError(null);
    } else setNicknameError(t("register.Invalid nickname"));
  };

  const nicknameKeyPressHandler = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  const handleSubmit = async () => {
    if (newNickname && regexNickname.test(newNickname) && swTermsAreAccepted && selectedTeam) {
      setTermsError("");
      await register({
        nickname: newNickname,
        teamId: selectedTeam._id.toString(),
        image: selectedTeam?.emblemUrl,
        emailNotification: yeppTermsAreAccepted,
      });
      document.activeElement.blur();
    } else {
      if (!swTermsAreAccepted) {
        setTermsError(t("register.You need to accept terms and conditions"));
      }

      if (!regexNickname.test(newNickname)) {
        setNicknameError(t("register.Invalid nickname"));
      }
      if (!selectedTeam) {
        setTeamError(t("register.select_your_favorite_team"));
      }
    }
  };

  useEffect(() => {
    if (selectedTeam) {
      setTeamError(null);
    }
  }, [selectedTeam]);

  return (
    <div className={styles.register_page}>
      <div className={styles.header}>
        <p>{t("championship_name")}</p>
      </div>
      <div className={styles.register_info}>
        <p>{parse(t("register.register_info_text"))}</p>
      </div>
      <div className={styles.trapeze}>
        <p>{parse(t("register.how_it_works"))}</p>
      </div>
      <div className={styles.user_details}>
        <h2 className={styles.title}>{t("register.your_detail")}</h2>
        <span className={styles.info_nickname}>
          <span className={styles.label}>
            {t("register.email")} {newEmail}
          </span>
        </span>
        <span className={styles.info_nickname}>
          <span className={styles.label}>{t("register.nickname")}</span>
          <input
            type="text"
            id="nickname"
            name="nickname"
            maxLength="15"
            placeholder="DanielPeters76"
            onChange={nicknameHandler}
            onKeyDown={nicknameKeyPressHandler}
            value={newNickname}
            defaultValue={nickname}
          />
          {nicknameError ? <span className={styles.error}>{nicknameError}</span> : null}
        </span>
        <Select
          label={t("register.select_your_favorite_team")}
          onClick={() => setTeamsPopupIsOpen(true)}
          defaultSelectedOption={
            selectedTeam?.name ? { value: selectedTeam.name, label: selectedTeam.name } : null
          }
          iconType="star"
          selectedTeam={selectedTeam}
        />
        {teamError ? <span className={styles.error}>{teamError}</span> : null}
        {teamsPopupIsOpen && (
          <TeamsPopup
            setSelectedTeam={setSelectedTeam}
            setOpenInfoPopUp={setTeamsPopupIsOpen}
            title={t("register.select_team")}
            data={teams}
          />
        )}
      </div>
      {
        <>
          <div className={styles.terms_and_conditions}>
            <h2 className={styles.title}>{t("register.terms_and_conditions")}</h2>
            <div>
              <button
                className={classNames(styles.btn, {
                  [styles.accepted]: swTermsAreAccepted,
                })}
                onClick={() => {
                  setSwTermsPopUpIsOpen(true);
                }}
              >
                <span className={styles.icon}>
                  <img src={checkedIcon} alt="+" />
                </span>
                <span className={styles.text}>{parse(t("swTerms.buttonText"))}</span>
              </button>
              {termsError ? <span className={styles.error}>{termsError}</span> : null}

              <button
                className={classNames(styles.btn, {
                  [styles.accepted]: yeppTermsAreAccepted,
                })}
                onClick={() => {
                  setYeppTermsPopUpIsOpen(true);
                }}
              >
                <span className={styles.icon}>
                  <img src={checkedIcon} alt="+" />
                </span>
                <span className={styles.text}>{parse(t("yeppTerms.buttonText"))}</span>
              </button>
              <p className={styles.disclaimer}>{t("register.terms_disclaimer")}</p>

              {swTermsPopUpIsOpen && (
                <TermsPopUp
                  text={t("swTerms.text")}
                  title={t("swTerms.title")}
                  subtitle={t("swTerms.subtitle")}
                  buttonText={t("register.acceptButton")}
                  setPopUpIsOpen={setSwTermsPopUpIsOpen}
                  acceptFunction={setSwTermsAreAccepted}
                  termsAreAccepted={swTermsAreAccepted}
                />
              )}
              {yeppTermsPopUpIsOpen && (
                <TermsPopUp
                  text={t("yeppTerms.text")}
                  title={t("yeppTerms.title")}
                  subtitle={t("yeppTerms.subtitle")}
                  buttonText={t("register.acceptButton")}
                  setPopUpIsOpen={setYeppTermsPopUpIsOpen}
                  acceptFunction={setYeppTermsAreAccepted}
                  termsAreAccepted={yeppTermsAreAccepted}
                />
              )}
            </div>
            {isLoading ? (
              <LoaderWithLogo />
            ) : (
              <div className={styles.submit_container}>
                <button
                  className={`${styles.btn} ${styles.fullWidth} ${styles.roundCorner}`}
                  onClick={handleSubmit}
                >
                  {t("register.create_profile")}
                </button>
              </div>
            )}
          </div>
        </>
      }
    </div>
  );
};

export default Register;
