import React, { useEffect, useRef, useState } from "react";

import styles from "./PredictionPopUp.module.scss";

import closeIcon from "assets/icons/close-icon.svg";

import { useStoreActions, useStoreState } from "easy-peasy";
import PredictionPopUpHeader from "../PredictionPopUpHeader/PredictionPopUpHeader";
import { useTranslation } from "react-i18next";
import { DynamicOdds, LoaderWithLogo } from "components";
import parse from "html-react-parser";
import classNames from "classnames";

const PredictionPopUp = ({
  gameId,
  setOpenPredictionPopUp,
  team_one,
  team_two,
  competitionName,
  date,
  userBets,
  filter,
}) => {
  const { t } = useTranslation();
  const modal = useRef();
  const { chalangeId } = useStoreState((store) => store.game);
  const { isLoading } = useStoreState((state) => state.matches);
  const { getOdds, postUserBets, setBets, updateUserBets } = useStoreActions(
    (actions) => actions.matches
  );
  const { newOdds, bets } = useStoreState((store) => store.matches);
  const { getMatches, getPlacedMatches } = useStoreActions((actions) => actions.matches);
  const [error, setError] = useState("");
  const [totalPoints, setTotalPoints] = useState(0);
  const [allBetsPlaced, setAllBetsPlaced] = useState(false);

  useEffect(() => {
    const handler = (event) => {
      if (!modal.current) {
        return;
      }

      if (!modal.current.contains(event.target)) {
        setOpenPredictionPopUp(false);
      }
    };

    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gameId && chalangeId) {
      const fetchData = async () => {
        await getOdds({ gameId, chalangeId, team_one, team_two });
      };
      fetchData().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chalangeId, gameId]);

  useEffect(() => {
    const correctScoreOddOptions = newOdds?.[gameId]?.odds?.find(
      (item) => item?.oddType?.externalBettingTypeId === 45
    );
    let teamOneScore = 0;
    let teamTwoScore = 0;
    for (let i = 0; i < correctScoreOddOptions?.oddOptions?.length; i++) {
      for (let j = 0; j < correctScoreOddOptions?.oddOptions?.[i]?.length; j++) {
        if (
          correctScoreOddOptions?.oddOptions[i][j]?.id ===
          bets[gameId]?.[correctScoreOddOptions?._id]
        ) {
          teamOneScore = i;
          teamTwoScore = j;
        }
      }
    }
    if (bets[gameId]) {
      if (
        (teamOneScore === 0 &&
          teamTwoScore === 0 &&
          newOdds[gameId]?.betTemplate?.oddTypeSelection?.length - 1 ===
            Object.values(bets[gameId]).length) ||
        newOdds[gameId]?.betTemplate?.oddTypeSelection?.length ===
          Object.values(bets[gameId]).length
      ) {
        setAllBetsPlaced(true);
      }
    }
  }, [bets]);

  const sendPrediction = async () => {
    const correctScoreOddOptions = newOdds?.[gameId]?.odds?.find(
      (item) => item?.oddType?.externalBettingTypeId === 45
    );
    let teamOneScore = 0;
    let teamTwoScore = 0;
    for (let i = 0; i < correctScoreOddOptions?.oddOptions?.length; i++) {
      for (let j = 0; j < correctScoreOddOptions?.oddOptions?.[i]?.length; j++) {
        if (
          correctScoreOddOptions?.oddOptions[i][j]?.id ===
          bets[gameId]?.[correctScoreOddOptions?._id]
        ) {
          teamOneScore = i;
          teamTwoScore = j;
        }
      }
    }
    if (
      (teamOneScore === 0 &&
        teamTwoScore === 0 &&
        newOdds[gameId]?.betTemplate?.oddTypeSelection?.length - 1 ===
          Object.values(bets[gameId]).length) ||
      newOdds[gameId]?.betTemplate?.oddTypeSelection?.length === Object.values(bets[gameId]).length
    ) {
      const correctScoreOddOptions = newOdds?.[gameId]?.odds?.find(
        (item) => item?.oddType?.externalBettingTypeId === 45
      );
      const winnerOddOptions = newOdds?.[gameId]?.odds?.find(
        (item) =>
          item?.oddType?.externalBettingTypeId === 69 &&
          item?.name === "Home Draw Away, Ordinary Time"
      );
      const goalscorer = newOdds?.[gameId]?.odds?.find(
        (item) => item?.oddType?.externalBettingTypeId === 30
      );
      const selectedOptionWinner = winnerOddOptions?.oddOptions?.find(
        (item) => item?._id === bets[gameId]?.[winnerOddOptions?._id]
      );
      let selectedOptionScore = null;

      let teamOneScore = 0;
      let teamTwoScore = 0;
      for (let i = 0; i < correctScoreOddOptions?.oddOptions?.length; i++) {
        for (let j = 0; j < correctScoreOddOptions?.oddOptions?.[i]?.length; j++) {
          if (
            correctScoreOddOptions?.oddOptions[i][j]?.id ===
            bets[gameId]?.[correctScoreOddOptions?._id]
          ) {
            teamOneScore = i;
            teamTwoScore = j;
            if (i === j) selectedOptionScore = { name: "Draw" };
            else if (i < j) selectedOptionScore = team_two;
            else selectedOptionScore = team_one;
          }
        }
      }

      let goalscorerTeam = goalscorer?.oddOptions.find(
        (item) => item._id === bets[gameId]?.[goalscorer._id]
      )?.teamId;

      if (!!goalscorerTeam) {
        if (
          (goalscorerTeam === team_one?.id && teamOneScore === 0) ||
          (goalscorerTeam === team_two?.id && teamTwoScore === 0)
        ) {
          setError(
            `${t("The Goalscorer cannot be from")} ${t(goalscorerTeam === team_one?.id ? team_one.name : team_two.name)} ${t("if this team doesn't score any goals!")}`
          );
          return;
        }
      }

      if (
        winnerOddOptions &&
        correctScoreOddOptions &&
        selectedOptionWinner?.name !== selectedOptionScore?.name
      ) {
        setError(
          t(
            "You cannot select a score that does not correspond to the team you have chosen as the winner"
          )
        );
        return;
      }

      if (userBets) {
        const data = {
          oddOptionsId: Object.values(bets[gameId]),
        };
        await updateUserBets({ chalangeId, gameId, data });
      } else {
        const data = {
          challengeId: chalangeId,
          gameId: gameId,
          oddOptionsId: Object.values(bets[gameId]),
          spreadBet: false,
          gameBetTemplateId: newOdds[gameId].betTemplate?._id,
        };
        await postUserBets(data);
      }
      switch (filter.value) {
        case "upcoming": {
          await getMatches(chalangeId);
          break;
        }
        case "placed": {
          await getPlacedMatches(chalangeId);
          break;
        }
        default:
          await getMatches(chalangeId);
      }
      setOpenPredictionPopUp(false);
    } else {
      setError(t("You need to bet on all type of odds."));
    }
  };

  useEffect(() => {
    if (userBets?.length) {
      userBets?.forEach((item) => {
        const payload = {
          gameId: item?.gameId,
          oddId: item?.oddId,
          oddOptionId: item?.oddOptionsId,
        };
        setBets(payload);
      });
      setError(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBets]);

  useEffect(() => {
    if (bets[gameId] && newOdds[gameId]?.odds.length) {
      let sum = 0;
      newOdds[gameId].odds.forEach((odd) => {
        if (odd?.oddType?.externalBettingTypeId === 45) {
          for (let i = 0; i <= 9; i++) {
            for (let j = 0; j <= 9; j++) {
              if (Object.values(bets[gameId]).includes(odd.oddOptions[i][j]?.id)) {
                if (!isNaN(odd.oddOptions[i][j]?.value)) {
                  sum += +odd.oddOptions[i][j]?.value;
                  break;
                }
              }
            }
          }
        } else {
          odd.oddOptions.forEach((oddOption) => {
            if (odd?.oddType?.externalBettingTypeId === 45) {
              for (let i = 0; i <= 9; i++) {
                for (let j = 0; j <= 9; j++) {
                  if (Object.values(bets[gameId]).includes(oddOption[i][j]?.id)) {
                    sum += +oddOption?.value;
                    break;
                  }
                }
              }
            } else if (Object.values(bets[gameId]).includes(oddOption._id)) {
              sum += oddOption?.value;
            }
          });
        }
      });
      setTotalPoints(sum);
      setError(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bets]);

  return (
    <div className={styles.pop_up_prediction_container}>
      <div className={styles.pop_up_container__inner} ref={modal}>
        <div className={styles.btn_close__container}>
          <button
            className={styles.btn_close}
            onClick={() => {
              setOpenPredictionPopUp(false);
            }}
          >
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <PredictionPopUpHeader
          homeTeam={team_one}
          awayTeam={team_two}
          competitionName={competitionName}
          date={date}
          withBackground={true}
        />
        {newOdds?.[gameId]?.odds.length && !isLoading ? (
          <div className={styles.odds_container}>
            <DynamicOdds
              odds={newOdds?.[gameId]?.odds}
              betTemplate={newOdds?.[gameId]?.betTemplate}
              team_one={team_one}
              team_two={team_two}
              userBets={userBets}
            />
          </div>
        ) : (
          !isLoading && (
            <p className={styles.no_odds}>{parse(t("No odds available at this moment."))}</p>
          )
        )}
        {error && <p className={styles.error}>{error}</p>}

        {!isLoading &&
        newOdds?.[gameId]?.odds.length &&
        newOdds[gameId]?.betTemplate?.oddTypeSelection?.length ===
          newOdds?.[gameId]?.odds?.length ? (
          <>
            <p className={styles.total_sum}>
              {t("matches.Pts")} <span>{totalPoints.toFixed(2)}</span>
            </p>
            <button
              className={classNames(styles.send, "btn")}
              onClick={sendPrediction}
              disabled={!allBetsPlaced}
            >
              {userBets ? t("matches.change_prediction") : t("matches.place_prediction")}
            </button>
          </>
        ) : null}
        {newOdds[gameId]?.betTemplate?.oddTypeSelection?.length !==
          newOdds?.[gameId]?.odds?.length && newOdds?.[gameId]?.odds.length ? (
          <p className={styles.no_odds}>
            You cannot place a prediction at this moment.
            <br />
            Please come back later.
          </p>
        ) : null}
        {isLoading ? (
          <div className={styles.loader}>
            <LoaderWithLogo />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PredictionPopUp;
