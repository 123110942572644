import React, { useEffect, useRef } from "react";

import styles from "./TermsPopUp.module.scss";

import closeIcon from "../../assets/icons/close-icon.svg";
import parse from "html-react-parser";
import classNames from "classnames";

const TermsPopUp = ({
  title,
  subtitle,
  text,
  acceptFunction,
  termsAreAccepted,
  setPopUpIsOpen,
  buttonText,
}) => {
  const modal = useRef(null);

  useEffect(() => {
    const handler = (event) => {
      if (!modal.current) {
        return;
      }

      if (!modal.current.contains(event.target)) {
        setPopUpIsOpen(false);
      }
    };

    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.pop_up_terms_container}>
      <div className={styles.pop_up_container__inner} ref={modal}>
        <div className={styles.btn_close__container}>
          <button
            className={styles.btn_close}
            onClick={() => {
              setPopUpIsOpen(false);
            }}
          >
            <img src={closeIcon} alt="close" />
          </button>
        </div>

        <div className={styles.data}>
          <h2 className={styles.terms_title}>{title}</h2>
          <h3 className={styles.terms_subtitle}>{subtitle}</h3>
          <div className={styles.data_container}>{parse(text)}</div>
        </div>

        {!termsAreAccepted && (
          <button
            className={classNames("btn", styles.btn)}
            onClick={() => {
              acceptFunction(true);
              setPopUpIsOpen(false);
            }}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default TermsPopUp;
