import React, { useEffect, useRef, useState } from "react";
import "./IndividualChat.scss";
import swLogo from "assets/sw-logo.png";
import {
  ChatContainer,
  ConversationHeader,
  Avatar,
  MessageList,
  Message,
  MessageInput,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import { useNavigate, useParams } from "react-router-dom";
import send from "assets/icons/send.svg";
import { useStoreActions, useStoreState } from "easy-peasy";
import { io } from "socket.io-client";
import Moment from "react-moment";
import parse from "html-react-parser";
import { isOtherDay, isToday } from "utils/globalFunctions";
import api from "api/axios";
import { CHAT } from "store/models/apiRoutes";
import { urlParamReplacer } from "store/models/apiRoutes";
import { useTranslation } from "react-i18next";

const IndividualChat = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const inputRef = useRef();
  const chatRef = useRef();
  const navigate = useNavigate();
  const imageRef = useRef();
  const messageListRef = useRef();

  const { access_token, userId } = useStoreState((store) => store.user);
  const { favoriteTeamChat, globalChatId, messages, profanityWords } = useStoreState(
    (store) => store.chat
  );
  const { profileImage } = useStoreState((store) => store.user);

  const { getMessages, setMessages } = useStoreActions((store) => store.chat);

  const [msgInputValue, setMsgInputValue] = useState("");
  const [allMessages, setAllMessages] = useState([]);

  const [height, setHeight] = useState(200);
  const [offset, setOffset] = useState(0);
  const [newRequestCanBeDone, setNeRequestCanBeDone] = useState(true);
  const limit = 20;

  // const scrollToBottom = () => {
  //   window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  // };

  useEffect(() => {
    setAllMessages(messages);
  }, [messages]);

  useEffect(() => {
    if (id) {
      getMessages({ conversationId: id, userId, offset: 0, limit });
      setOffset((prev) => prev + limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, userId]);
  const [socket, setSocket] = useState(null);

  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const socketInstance = io(process.env.REACT_APP_SOCKET_PATH, {
      transports: ["websocket"],
      reconnectionDelayMax: 10000,
      pingTimeout: 60000,
      query: {
        authorization: access_token,
      },
      auth: {
        token: access_token,
      },
    });
    function onConnect() {
      console.log("CONNECTED");
      setIsConnected(true);
      console.log(socketInstance);
    }

    function onException() {
      console.log("onException");
      setIsConnected(false);
    }

    function onDisconnect() {
      console.log("DISCONNECTED");
      setIsConnected(false);
      setTimeout(() => {
        socketInstance.connect();
      }, 2000);
    }

    function onReceiveMessage(value) {
      if (value?.lastMessage.conversationId === id) {
        const newMessage = {
          text: value?.lastMessage.text,
          image: value?.lastMessage?.mediaURL,
          direction: value?.lastMessage.sender._id === userId ? "outgoing" : "incoming",
          sender: value?.lastMessage.sender.nickname,
          sentTime: value?.lastMessage.created,
          profilePicture: value?.lastMessage.sender.profilePicture,
        };
        setAllMessages((prev) => [...prev, newMessage]);
      }
    }

    socketInstance.on("connect", onConnect);
    socketInstance.on("disconnect", onDisconnect);
    socketInstance.on("receive_message", onReceiveMessage);
    socketInstance.on("exception", onException);
    setSocket(socketInstance);

    return () => {
      console.log("return");
      socketInstance.off("connect", onConnect);
      socketInstance.off("disconnect", onDisconnect);
      socketInstance.off("receive_message", onReceiveMessage);
      setAllMessages([]);
      setMessages({ messages: [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setHeight(chatRef?.current?.offsetTop);
  }, [chatRef]);

  const handleSend = async () => {
    if (msgInputValue && msgInputValue.replaceAll("&nbsp;", "").trim() !== "") {
      let isProfane = false;
      const words = msgInputValue.split(" ");
      if (profanityWords.includes(msgInputValue.toLowerCase())) isProfane = true;
      else {
        words.forEach((word) => {
          if (profanityWords.includes(word.toLowerCase())) {
            isProfane = true;
          } else {
            profanityWords.forEach((item) => {
              if (item.toLowerCase() === word.toLowerCase() || word.includes(item.toLowerCase()))
                isProfane = true;
              if (item.toLowerCase() === word.toLowerCase()) isProfane = true;
            });
          }
        });
      }

      if (!isProfane && isConnected) {
        socket.emit("send_message", {
          conversationId: id,
          message: {
            text: msgInputValue,
            mediaURL: null,
          },
        });
      }
      setMsgInputValue("");
      inputRef.current.focus();
    }
  };

  // const addImageHandler = () => {
  //   imageRef.current.click();
  // };

  const imageHandler = async (e) => {
    if (imageRef?.current?.files[0]) {
      try {
        const body = new FormData();
        body.append("file", e.target.files[0]);
        const conversationId = id;
        const { data } = await api.post(urlParamReplacer(CHAT.MEDIA, conversationId), body);
        const { location } = data;
        socket.emit("send_message", {
          conversationId: id,
          message: {
            text: null,
            mediaURL: location,
          },
        });
        imageRef.current.value = null;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onYReachStart = async () => {
    if (newRequestCanBeDone) {
      const conversationId = id;
      setNeRequestCanBeDone(false);
      try {
        const { data } = await api.get(
          urlParamReplacer(CHAT.MESSAGES, conversationId) + `?offset=${offset}&limit=${limit}`
        );

        const messages = data?.messages.map((item) => {
          return {
            text: item.text,
            image: item.mediaURL,
            direction: item.sender._id === userId ? "outgoing" : "incoming",
            sender: item.sender.nickname,
            sentTime: item.created,
            profilePicture: item.sender.profilePicture,
          };
        });

        setAllMessages((prev) => [...messages.reverse(), ...prev]);
        if (messages?.length < limit) {
          setNeRequestCanBeDone(false);
        } else {
          setTimeout(() => {
            setNeRequestCanBeDone(true);
            setOffset((prev) => prev + limit);
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        setNeRequestCanBeDone(true);
      }
    }
  };

  return (
    <div
      className="individual_chat container"
      ref={chatRef}
      style={{ height: `calc(100dvh - ${height}px - 20px)` }}
    >
      <ChatContainer className="chat_container">
        <ConversationHeader className="chat_header">
          <ConversationHeader.Back onClick={() => navigate("/chat")} />
          {/*<Avatar*/}
          {/*  src={*/}
          {/*    globalChatId === id ? swLogo : profileImage?.includes("http") ? profileImage : swLogo*/}
          {/*  }*/}
          {/*  name="Global chat"*/}
          {/*  size="fluid"*/}
          {/*/>*/}
          <ConversationHeader.Content
            userName={
              globalChatId === id
                ? t("chat.globalChat")
                : t(favoriteTeamChat?.name.replace("PUBLIC FAN CLUB", "").trim()) + " Chat"
            }
          />
        </ConversationHeader>

        <MessageList
          scrollBehavior="auto"
          className="message_list"
          onYReachStart={onYReachStart}
          loadingMorePosition="top"
          autoScrollToBottom={true}
          autoScrollToBottomOnMount={true}
          ref={messageListRef}
          disableOnYReachWhenNoScroll={true}
        >
          {allMessages?.length > 0 &&
            allMessages?.map((message, i) => (
              <React.Fragment key={i}>
                {i > 0 && isOtherDay(message?.sentTime, allMessages?.[i - 1]?.sentTime) && (
                  <MessageSeparator className="separator">
                    {isToday(new Date(message?.sentTime)) ? (
                      <span className="separator-content">{t("chat.today")}</span>
                    ) : (
                      <Moment format="DD MMM YYYY" className="separator-content">
                        {message.sentTime}
                      </Moment>
                    )}
                  </MessageSeparator>
                )}
                {message.direction === "incoming" && (
                  <Message
                    model={message}
                    className={`message ${
                      i > 0 && allMessages[+i - 1]?.sender === message.sender ? "no-avatar" : ""
                    } ${message?.image ? "with-image" : "text"}`}
                    avatarPosition="tl"
                  >
                    {/*{((i > 0 && allMessages[+i - 1]?.sender !== message.sender) || i === 0) && (*/}
                    {/*  <Avatar*/}
                    {/*    src={*/}
                    {/*      message.profilePicture?.includes("http")*/}
                    {/*        ? message.profilePicture*/}
                    {/*        : message.profilePicture*/}
                    {/*    }*/}
                    {/*    name={message.sender}*/}
                    {/*    style={{ width: "30px" }}*/}
                    {/*  />*/}
                    {/*)}*/}
                    <Message.CustomContent>
                      {((i > 0 && allMessages[+i - 1]?.sender !== message.sender) || i === 0) && (
                        <>
                          <span className="user_name">{message.sender}</span> <br />
                        </>
                      )}
                      {parse(`${message?.text}`)}
                      <span className="sent_time">
                        <Moment format="HH:mm">{message.sentTime}</Moment>
                      </span>
                    </Message.CustomContent>
                    {message.image && (
                      <Message.ImageContent
                        src={message.image}
                        alt="Akane avatar"
                        className="message-with-image"
                      />
                    )}
                  </Message>
                )}
                {message.direction === "outgoing" && (
                  <Message
                    model={message}
                    className={`message ${
                      i > 0 && allMessages[+i - 1]?.sender === message.sender ? "no-avatar" : ""
                    } ${message?.image ? "with-image" : "text"}`}
                    avatarPosition="tr"
                  >
                    {/*{((i > 0 && allMessages[+i - 1]?.sender !== message.sender) || i === 0) && (*/}
                    {/*  <Avatar src={profileImage} name={message.sender} style={{ width: "30px" }} />*/}
                    {/*)}*/}
                    <Message.CustomContent>
                      {((i > 0 && allMessages[+i - 1]?.sender !== message.sender) || i === 0) && (
                        <>
                          <span className="user_name">{message.sender}</span> <br />
                        </>
                      )}
                      {parse(`${message?.text}`)}
                      <span className="sent_time">
                        <Moment format="HH:mm">{message.sentTime}</Moment>
                      </span>
                    </Message.CustomContent>
                    {message.image && (
                      <Message.ImageContent
                        src={message.image}
                        alt="Akane avatar"
                        className="message-with-image"
                      />
                    )}
                  </Message>
                )}
              </React.Fragment>
            ))}
        </MessageList>

        <div as={MessageInput} className="input_container">
          {/* <div
            className="attach_button"
            onClick={addImageHandler}
          >
            <img
              src={circle}
              alt="sss"
            />
          </div> */}
          <MessageInput
            ref={inputRef}
            onChange={(msg) => setMsgInputValue(msg)}
            // onClick={() => scrollToBottom()}
            value={msgInputValue}
            sendButton={false}
            attachButton={false}
            onSend={handleSend}
          />
          <div className="send_button" onClick={handleSend}>
            <img src={send} alt="send" />
          </div>
        </div>
      </ChatContainer>
      <input
        type="file"
        className="photo_input"
        ref={imageRef}
        accept="image/*"
        onChange={imageHandler}
      />
    </div>
  );
};

export default IndividualChat;
