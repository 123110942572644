import React, { useEffect, useState } from "react";
import "./Matches.scss";

import { MatchCard, LoaderWithLogo } from "components";
import { useStoreState, useStoreActions } from "easy-peasy";

import { useTranslation } from "react-i18next";
import TEASER_EN from "assets/TEASER_EN.png";
import TEASER_DE from "assets/TEASER_DE.png";
import KO_EN from "assets/KO_PHASE-EN.png";
import KO_DE from "assets/KO_PHASE-DE.png";
import SelectComponent from "components/SelectComponent/SelectComponent";
import joe from "assets/joe.svg";
import Outrights from "components/Outrights/Outrights";

const Matches = () => {
  const { t } = useTranslation();
  const { chalangeId } = useStoreState((store) => store.game);
  const { profileImage, nickname, points, rank, lang } = useStoreState((store) => store.user);
  const { matches, isLoadingMatches } = useStoreState((store) => store.matches);
  // const { previousBest, hasVoted } = useStoreState((store) => store.votePlayer);
  const { getMatches, getPrevMatches, getPlacedMatches, getOutrights } = useStoreActions(
    (actions) => actions.matches
  );
  const { getUserStats } = useStoreActions((actions) => actions.user);
  const { getChalangeID } = useStoreActions((actions) => actions.game);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const filters = [
    {
      value: "upcoming",
      label: t("matches.Upcoming"),
    },
    {
      value: "placed",
      label: t("matches.Placed"),
    },
    {
      value: "results",
      label: t("matches.Results"),
    },
  ];
  const [filter, setFilter] = useState(filters[0]);

  useEffect(() => {
    const fetchData = async () => {
      if (chalangeId) {
        switch (filter.value) {
          case "upcoming": {
            await getMatches(chalangeId);
            break;
          }
          case "placed": {
            await getPlacedMatches(chalangeId);
            break;
          }
          default:
            await getPrevMatches(chalangeId);
        }
        // if (filter.value === "upcoming") {
        // 	await getMatches(chalangeId);
        // } else {
        // 	await getPrevMatches(chalangeId);
        // }
        getUserStats(chalangeId);
      }
    };
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chalangeId, filter]);

  useEffect(() => {
    getChalangeID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chalangeId) {
      getOutrights(chalangeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chalangeId]);

  return isLoading ? (
    <div className="loader_container">
      <LoaderWithLogo />
    </div>
  ) : (
    <div className="matches">
      {/* <BestPlayer previousBest={previousBest} hasVoted={hasVoted} /> */}
      <div className="container">
        <div className="user-profile">
          <div className="user-profile--data">
            {/*<span className="user-image">*/}
            {/*  {profileImage && (*/}
            {/*    <img src={profileImage?.includes("http") ? profileImage : joe} alt={nickname} />*/}
            {/*  )}*/}
            {/*</span>*/}
            <span className="nickname">{nickname}</span>
          </div>
          <div className="user-profile--ranking">
            <span className="points">
              {points} <span>{t("matches.pts")}</span>
            </span>
            <span className="rank">#{rank === 0 ? "-" : rank}</span>
          </div>
        </div>
      </div>

      {!isLoadingMatches && <Outrights />}

      <h2 className="container">{t("menu.matches")}</h2>
      <div className="container">
        <SelectComponent options={filters} setValue={setFilter} value={filter} colored />
      </div>

      {isLoadingMatches && <LoaderWithLogo />}
      {matches?.length > 0 && !isLoadingMatches ? (
        <ul className="matches-list container">
          {matches.map((matchData, i) => (
            <li key={i} className="match-card">
              <MatchCard {...matchData} filter={filter} />
            </li>
          ))}
        </ul>
      ) : (
        !isLoadingMatches && (
          <div className="no-results">
            {/* <img src={lang == "de" ? TEASER_DE : TEASER_EN} alt="no results" /> */}
          </div>
        )
      )}
    </div>
  );
};

export default Matches;
