import React, { useState } from "react";

import "./Select.scss";

import downIcon from "assets/icons/down-icon.svg";
import starIcon from "assets/icons/star.svg";
import whiteStarIcon from "assets/icons/starWhite.svg";

const Select = ({ label, onClick, defaultSelectedOption, iconType, selectedTeam }) => {
  const [isExtended, setIsExtended] = useState(false);

  const extendSelect = () => {
    setIsExtended(!isExtended);
  };

  return (
    <div className={`select-container${isExtended ? " extended" : ""}`}>
      <div className="dropdown" onClick={onClick !== undefined ? onClick : extendSelect}>
        <span>{label && defaultSelectedOption == null ? label : defaultSelectedOption.label}</span>
        <span className="icon">
           <img
             src={iconType !== "star" ? downIcon : selectedTeam
               ? whiteStarIcon
               : starIcon}
             alt="icon"
           />
        </span>
      </div>
    </div>
  );
};
export default Select;
