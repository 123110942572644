import React from "react";
import { Outlet } from "react-router-dom";

import Navigation from "./Navigation/Navigation";

import "../styles/base.scss";
import banner_en from "assets/banner_en.png";
import banner_de from "assets/banner_de.png";
import { useStoreState } from "easy-peasy";

const Layout = () => {
  const { lang } = useStoreState((store) => store.user);
  return (
    <>
      <h2 className="main-title">Dashboard</h2>
      <div className="banner">
        <img src={lang === "de" ? banner_de : banner_en} alt="sponsor" />
      </div>
      <main className="main-container">
        <div className="container">
          <Navigation />
        </div>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
